const settings = {
  api_url: '/api',
  socket_url: '/',      // socket url; any sub dir may not work! eg /api will not work
  languages: ['pl', 'en'],              // All available languages
  // app_title: 'Custom CTF Name',              // Custom title tag value to set
  // app_footer: 'Custom Footer Info ©2023',    // Custom value inside app footer
  // app_login_text: 'Custom Login Text',       // Custom value used on login screen
  // app_logo: 'logo_light_small_changed.png',  // Custom logo to use on login, register and password reset screens; filename from public/images/customization directory
  // app_logo_menu: 'logo_light_changed.png',   // Custom logo to use in menu; filename from public/images/customization directory
  // app_loader: 'loader_changed.svg',          // Custom logo to use in app loader; filename from public/images/customization directory
  // app_favicon: 'favicon_changed.png',        // Custom favicon to use; filename from public/images/customization directory
  default_lang: 'pl',                   // Default app language
  installation_type: 'cloud',           // installation type: [cloud, box]
  recruitment: false,                   // true if this is install for recruitment purposes
  ajax_refresh_rate: 0,                 // Wyrażony w sekundach, odstęp czasu pomiędzy pobieraniem danych widoku maszyn i scenariuszy
  registration: false,                  // Is registration form enabled
  password_reset: false,                // Is password reset form enabled
  instruction: false,                   // Show instruction button in top nav
  // stack_switch_state: 'poweroff',    // Action to make on previous stack when switching stack, if not set no prompt will show
  // stack_switch_default_change_state: false, // Make stack switch prompt state change checkbox checked for default; if false or not set will be not checked
  scenarios_blocking_enabled: false,    // Are scenario reservations enabled?
  overlord_enabled: true,

  external_link: {
    url: 'https://discord.gg/QK6eKq5RyM',
    text: 'Support',
    icon: 'images/customization/discord.png'
  },

  stack_types: [                        // Stack types available for this install
    'box', 'cloud', 'recruitment', 'ctf_lab', 'ctf', 'stm_academy_web', 'stm_academy_network'
  ],
  ca_certificates: [
    'hackingdept', 'hackgroup'
  ],
  demo_types: [
    'full', 'partial'
  ],
  roles: [                              // Roles available for set for users
    {'role': 1, 'name': 'admin'},
    {'role': 2, 'name': 'user'},
    {'role': 3, 'name': 'user_recruitment'},
    {'role': 4, 'name': 'user_ctf_ro'}
  ],
  networks: [                           // VPN Networks available for this install
    // {'network_name': 'hackgroup-dmz', 'title': 'HackGroup DMZ'},
    {'network_name': 'hackbank-lan', 'title': 'HackBank LAN'},
    // {'network_name': 'hackgroup-malwarelab', 'title': 'HackGroup MalwareLab'},
    // {'network_name': 'hackstock-lan', 'title': 'HackStock LAN'},
    // {'network_name': 'ctf-lab-network', 'title': 'CTF Lab Network'},
    {'network_name': 'attackers-network', 'title': 'Attacker\'s Network'},
    {'network_name': 'management-network', 'title': 'HD Management Network'},
    {'network_name': 'webnet', 'title': 'WebNetwork'},
    {'network_name': 'external-network', 'title': 'External Network'}
  ]
}

const views = {
  sa:"fNOvREpuGbsjgG5EXYfFA7J0Ebb2G0Ua",
  ad:"RkGXhJ3L0XrqTd9JSGVtvdRZJ99A6isu",
  us:"iZeephooGaiquo3Quiesh0ooLoh5xeij",
  re:"be8oe6cae9Kaequ5Gahsiexoobeequey",
  ucr:"IFoiTAfaitANxvGy58Pb5J1iwoXmqfEH"
}

export default settings;
export { views }
